<template>
  <div class="container">
    <div class="checkin">
      <el-row :gutter="20">
        <el-col :span="5">
          <memberleft :activeIndex="leftPannelSelected" />
        </el-col>
        <el-col :span="19">
          <div class="right_pannel" v-if="userInfo">
            <!-- <div class="pannel-top" style="width:100%;overflow:hidden; height:150px;"></div> -->
            <div>
              <div
                class="card-wrapper"
                :style="'background-image:url(' + gradeConfigs[currentIndex].bg +');background-size:cover;'"
              >
                <el-card
                  class="member-card"
                  :style="'background-image:url(' + gradeConfigs[currentIndex].icon +');color:'+gradeConfigs[currentIndex].textColor"
                  style="width: 533px;height: 214px;"
                >
                    <div class="card-top">
                      <div class="card-name-content">
                        <img src="../../assets/member-user-default.png" style="width:60px;height:60px;">
                        <span class="card-name">{{registerData.name}}</span>
                      </div>
                      <div
                        class="verify-status"
                        @click="goVerify(registerData.verifyStatus)"
                        :style="'background-image:url(' + gradeConfigs[currentIndex].verifyBg +');background-size:cover;'"
                      >
                      <img
                          style="width:13px;margin-right:10px;height:16px;"
                          :src="currentCardIcon"
                          alt=""
                        >
                      {{ verifyStatusText(registerData.verifyStatus) }}</div>
                    </div>
                    <div class="card-id">
                      <span>NO.{{ registerData.memberId }}</span>
                      <img >
                      <div class="copy" @click="copyId(registerData.memberId)" :style="'background-image:url(' + gradeConfigs[currentIndex].copyBg +');background-size:cover;color:'+
                       gradeConfigs[currentIndex].copyColor"> 
                      复制</div>
                    </div>
                    <div class="member-level" :style="{'background-image': 'url(' + gradeConfigs[currentIndex].levelBorder +')'}">
                      {{userInfo.gradeConfig.gradeName}}   {{userInfo.level}}
                      <img v-for="i in completedGrade" :src="gradeConfigs[currentIndex].levelIcon" class="level-image" :key="'c_'+i"/>
                      <img v-for="i in unfillGrade" :src="gradeConfigs[currentIndex].levelNoIcon" class="level-image" :key="'n_'+i"/>
                    </div>
                    <div class="card-bottom">
                      <div>有效期至
                        <span class="card-bottom-item">{{registerData.effectiveTimeEnd}}</span>
                      </div>
                    </div>
                </el-card>
                <div class="grade-info-wrap" v-if="userInfo">
                  <div class="grade-info-item" :style="'background-image:url(' + gradeConfigs[currentIndex].levelBg +');background-size:cover;'">
                    <img src="../../assets/member-level-left-1.png" style="width:23px;height:27px;">
                    <div class="grade-current" :style="'margin-left:20px;margin-right:10px;width:110px;color:'+gradeConfigs[currentIndex].gradeColor"><span>成长值</span><span class="grade-num">{{userInfo.growthValue}}</span></div>
                    <span class="grade-next" v-if="userInfo.grade !== 'DIAMOND'">距{{userInfo.nextGradeConfig.gradeName}}还需 {{(userInfo.nextGrowthValue - userInfo.growthValue) | formatCount}}</span>
                  </div>
                  <div class="grade-info-item" :style="'background-image:url(' + gradeConfigs[currentIndex].levelBg +');background-size:cover;'">
                    <img src="../../assets/member-level-right-1.png" style="width:37px;height:26px;">
                    <div class="grade-current" :style="'margin-left:5px;color:'+gradeConfigs[currentIndex].gradeColor"><span>飞行次数</span><span class="grade-num">{{userInfo.flyCount}}</span></div>
                    <span class="grade-next" v-if="userInfo.grade !== 'DIAMOND'">距{{userInfo.nextGradeConfig.gradeName}}还需飞行{{(userInfo.nextFlyCount - userInfo.flyCount) | formatCount}}次</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="grade-rights">
              <div class="right-title">专享权益</div>
              <div class="right-wrapper" v-if="memberGradeRight">
                <div
                  class="right-list"
                  v-for="(item, idx) in memberGradeRight.memberRights"
                  :key="idx"
                >
                  <div class="right-top">
                    <div class="line"></div>
                    <span class="name">{{ item.memberRightCategory.rightCategoryName }}</span>
                  </div>
                  <div class="right-items" v-if="item.gradeRight.length">
                    <div
                      v-for="r in item.gradeRight"
                      :key="r.id"
                      class="right-item"
                    >

                      <img
                        src='../../assets/right_icon_birth.png'
                        style="display:flex;margin:0 auto;width:39px;height:39px;"
                      >
                      <span class="right-name">{{r.rightTitle}}</span>
                      <span class="right-value">{{r.rightSubTitle}}</span>
                    </div>
                  </div>
                   <div v-else class="right-tips">
                      <span class="right-tip no-tip" v-if="currentIndex === 3">{{rightTips[0]}}</span>
                      <span class="right-tip no-tip" v-else>{{rightTips[1]}}</span>
                    </div>
                </div>
                <div class="right-list">
                  <!-- 小程序 -->
                  <div style="display:flex;align-items: center;justify-content: center;height:100%;flex-direction: column;">
                    <img
                      style="width:120px;height:120px"
                      src="../../assets/wxmp.png"
                      alt
                    />
                    <div class="wx-tip">
                      <span>扫码进入小程序</span><br/>
                      <span>查看详情</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import memberleft from "../../components/MemberLeft";
import {
  userinfo,
  fetchPhoneValidateCode,
  updateuser,
  queryMemberRight
} from "@/api/newsapi";
import DetailList from "@/components/DescriptionList";
import countryData from "@/utils/country-level3-data.js";
import { CURRENT_USER } from "@/store/mutation-types";
import moment from "moment";
const DetailListItem = DetailList.Item;
const gradeMap = ["BRONZE", "SILVER", "GOLD", "DIAMOND"];
const levelMap = ["V1", "V2", "V3", "V4", "V5"];
const gradeConfigs = [
  {
    bg: require("../../assets/member-verify-bg-1.png"),
    icon: require("../../assets/member-verify-icon-1.png"),
    levelBorder: require("../../assets/member-level-border-1.png"),
    levelIcon: require("../../assets/member_level_icon_1.png"),
    levelNoIcon: require("../../assets/member_level_icon_no_1.png"),
    levelBg: require("../../assets/member-level-bg-1.png"),
    copyBg: require("../../assets/member-level-copy-1.png"),
    verifyBg: require("../../assets/member-level-verify-1.png"),
    copyColor: "#26476A",
    textColor: "#C6C7D4",
    gradeColor: "#032738"
  },
  {
    bg: require("../../assets/member-verify-bg-2.png"),
    icon: require("../../assets/member-verify-icon-2.png"),
    levelBorder: require("../../assets/member-level-border-2.png"),
    levelIcon: require("../../assets/member_level_icon_2.png"),
    levelBg: require("../../assets/member-level-bg-2.png"),
    copyBg: require("../../assets/member-level-copy-2.png"),
    verifyBg: require("../../assets/member-level-verify-2.png"),
    copyColor: "#FFFFFF",
    textColor: "#595757",
    gradeColor: "#595757"
  },
  {
    bg: require("../../assets/member-verify-bg-3.png"),
    icon: require("../../assets/member-verify-icon-3.png"),
    levelBorder: require("../../assets/member-level-border-3.png"),
    levelIcon: require("../../assets/member_level_icon_3.png"),
    levelNoIcon: require("../../assets/member_level_icon_no_3.png"),
    levelBg: require("../../assets/member-level-bg-3.png"),
    copyBg: require("../../assets/member-level-copy-3.png"),
    verifyBg: require("../../assets/member-level-verify-3.png"),
    copyColor: "#FFFFFF",
    textColor: "#7F4F21",
    gradeColor: "#7F4F21"
  },
  {
    bg: require("../../assets/member-verify-bg-4.png"),
    icon: require("../../assets/member-verify-icon-4.png"),
    levelBorder: require("../../assets/member-level-border-4.png"),
    levelIcon: require("../../assets/member_level_icon_4.png"),
    levelNoIcon: require("../../assets/member_level_icon_no_4.png"),
    levelBg: require("../../assets/member-level-bg-4.png"),
    copyBg: require("../../assets/member-level-copy-4.png"),
    verifyBg: require("../../assets/member-level-verify-4.png"),
    copyColor: "#7F4F21",
    textColor: "#FFDE94",
    gradeColor: "#241760"
  }
];
export default {
  components: { memberleft },
  data() {
    return {
      input: "",
      callphoneloading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      gradeConfigs: gradeConfigs,
      phoneValidateCodeLabel: "获取验证码",
      phoneCodeIntervalMax: 60,
      leftPannelSelected: "memberHand",
      pwdStrong: 0,
      countryData: countryData,
      baseValidateUrl: "/api/user/createCaptcha?random=",
      picValidateUrl: "",
      validateCodeRequest: {
        phone: "",
        picValidateCode: "",
        action: "",
        sendType: "updateuserinfo"
      },
      disableUpdate: false,
      registerData: {
        name: "",
        certificateType: "",
        certificateNo: "",
        area: [],
        gender: "",
        verifyStatus: "",
        nextGrade: "",
        birthday: "",
        address: "",
        flyCount: "",
        point: "",
        mileage: "",
        sex: "",
        email: "",
        phone: "",
        phoneValidateCode: "",
        picValidateCode: "",
        checked: false,
        grade: "",
        memberId: "" //会员卡号
      },
      certTypeOptions: [
        {
          value: "NI",
          label: "身份证"
        },
        {
          value: "PP",
          label: "护照"
        },
        {
          value: "GM",
          label: "军官证"
        },
        {
          value: "HX",
          label: "回乡证"
        },
        {
          value: "TB",
          label: "台胞证"
        },
        {
          value: "TW",
          label: "台湾通行证"
        },
        {
          value: "HY",
          label: "海员证"
        },
        {
          value: "QT",
          label: "其它"
        }
      ],
      verifyStatusOptions: [
        {
          value: "NOT_CERTIFIED",
          label: "未认证"
        },
        {
          value: "CERTIFIED_FAILED",
          label: "认证失败"
        },
        {
          value: "CERTIFIED_SUCCESS",
          label: "已认证"
        },
        {
          value: "WAITING_CERTIFIED",
          label: "认证中"
        }
      ],
      gradeClass: ["bronze", "silver", "gold", "diamond"],
      gradeOptions: [
        {
          value: "BRONZE",
          label: "鸿鹄普卡"
        },
        {
          value: "SILVER",
          label: "鸿鹄银卡"
        },
        {
          value: "GOLD",
          label: "鸿鹄金卡"
        },
        {
          value: "DIAMOND",
          label: "鸿鹄白金卡"
        }
      ],
      currentRights: [
        { name: "积分赠送", value: "500积分" },
        { name: "积分赠送", value: "1倍" },
        { name: "积分赠送", value: "500积分" }
      ],
      currentLevelData: {
        /* 等级 */
        level: "青翼",
        code: "V1",
        time: "2022-11-22",
        completedGrade: 1
      },
      nextLevelData: {
        growth: "1234",
        flightCount: "1234",
        nextLevel: "银翼",
        nextDay: "123",
        nextCount: "6"
      },
      userInfo: null,
      memberGradeRight: null, // 会员权益
      gradeMap: gradeMap,
      levelMap: levelMap,
      rightTips: ["敬请期待", "升级后可享更多权益"],
      currentIndex: 0 // 当前等级
    };
  },
  created() {
    this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
    this.query();
    this.queryMemberRight();
  },
  computed: {
    unfillGrade() {
      let level = (this.userInfo && this.userInfo.level) || 0;
      return 4 - this.levelMap.indexOf(level);
    },
    completedGrade() {
      let level = (this.userInfo && this.userInfo.level) || 0;
      return this.levelMap.indexOf(level) + 1;
    },
    currentCard() {
      let cardImgs = [];
      switch (this.registerData.grade) {
        case "BRONZE":
          cardImgs.push(
            require("../../assets/broze-card.png"),
            require("../../assets/broze-card.png"),
            require("../../assets/silver-card.png")
          );
          break;
        // return require("../../assets/broze-card.png");
        case "GOLD":
          cardImgs.push(
            require("../../assets/silver-card.png"),
            require("../../assets/gold-card.png"),
            require("../../assets/diamond-card.png")
          );
          break;
        // return require("../../assets/gold-card.png");
        case "SILVER":
          cardImgs.push(
            require("../../assets/broze-card.png"),
            require("../../assets/silver-card.png"),
            require("../../assets/gold-card.png")
          );
          break;
        // return require("../../assets/silver-card.png");
        case "DIAMOND":
          cardImgs.push(
            require("../../assets/gold-card.png"),
            require("../../assets/diamond-card.png"),
            require("../../assets/diamond-card.png")
          );
          break;
        // return require("../../assets/diamond-card.png");
        default:
          cardImgs.push(
            require("../../assets/broze-card.png"),
            require("../../assets/broze-card.png"),
            require("../../assets/silver-card.png")
          );
        // return require("../../assets/gold-card.png");
      }
      return cardImgs;
    },
    currentCardIcon() {
      switch (this.registerData.grade) {
        case "BRONZE":
          return require("../../assets/member-verify-icon.png");
        case "GOLD":
          return require("../../assets/member-verify-icon.png");
        case "SILVER":
          return require("../../assets/member-verify-icon.png");
        case "DIAMOND":
          return require("../../assets/member-verify-icon.png");
        default:
          return require("../../assets/member-verify-icon.png");
      }
    }
  },
  mounted() {
    // this.query();
    // this.queryMemberRight();
    this.currentUser = Vue.ls.get(CURRENT_USER);
    if (
      !this.currentUser ||
      this.currentUser.phone == undefined ||
      this.currentUser.phone.length <= 0
    ) {
      this.$router.push({ path: "/login", query: { t: new Date().getTime() } });
    }
  },
  methods: {
    query() {
      var self = this;
      userinfo({ t: {} }).then(res => {
        if (res.success) {
          self.userInfo = res.data;
          self.registerData.name = res.data.name;
          self.registerData.certificateType = res.data.certificateType;
          self.registerData.certificateNo = res.data.certificateNo;
          self.registerData.area = res.data.area;
          self.registerData.verifyStatus = res.data.verifyStatus;
          self.registerData.nextGrade = res.data.nextGrade;
          self.registerData.grade = res.data.grade;
          self.registerData.birthday = res.data.birthday;
          self.registerData.address = res.data.address;
          self.registerData.flyCount = res.data.flyCount;
          self.registerData.point = res.data.point;
          self.registerData.memberId = res.data.memberId;
          self.registerData.effectiveTimeEnd = moment(
            res.data.effectiveTimeEnd
          ).format("YYYY-MM-DD");
          self.registerData.mileage = res.data.mileage;
          self.registerData.sex = res.data.sex;
          self.registerData.email = res.data.email;
          self.registerData.phone = res.data.phone;
          self.registerData.nextGradePoint = res.data.nextGradePoint;
          if (
            self.registerData.verifyStatus === "CERTIFIED_SUCCESS" ||
            self.registerData.verifyStatus === "WAITING_CERTIFIED"
          ) {
            self.disableUpdate = true;
          }
          Vue.ls.set(CURRENT_USER, res.data);
          self.currentIndex =
            self.userInfo && self.gradeMap.indexOf(self.userInfo.grade);
        }
      });
    },
    gradeText(grade) {
      for (let gradeOption of this.gradeOptions) {
        if (gradeOption.value === grade) {
          return gradeOption.label;
        }
      }
      return "";
    },
    verifyStatusText(status) {
      for (let verifyStatusOption of this.verifyStatusOptions) {
        if (verifyStatusOption.value === status) {
          return verifyStatusOption.label;
        }
      }

      return "未认证";
    },
    goVerify(currentStatus) {
      if (
        currentStatus == "CERTIFIED_SUCCESS" ||
        currentStatus == "WAITING_CERTIFIED"
      ) {
        return;
      }
      if (
        this.currentUser.certificateNo === undefined ||
        this.currentUser.certificateNo.length < 0
      ) {
        this.$alert("请先到个人中心完善证件信息");
        return;
      }
      this.$router.push({ path: "/member/verify" });
    },
    fetchPhoneValidateCodeHandler() {
      if (this.registerData.phone.length != 11) {
        this.$message.error("手机号码校验不通过，请核实输入11位手机号");
        this.$refs.phone.focus();
        return;
      }
      if (this.registerData.picValidateCode.length < 4) {
        this.$message.error("请先输入图像验证码!");
        this.$refs.picValidateCode.focus();
        return;
      }
      this.validateCodeRequest.phone = this.registerData.phone;
      this.validateCodeRequest.picValidateCode = this.registerData.picValidateCode;
      var that = this;
      this.callphoneloading = true;
      fetchPhoneValidateCode(this.validateCodeRequest).then(res => {
        this.callphoneloading = false;
        if (res.success) {
          that.phoneCodeIntevel();
        } else {
          that.$message.error(res.errorMessage);
        }
      });
    },
    changeValidateCode() {
      this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
    },
    updateUserHandler() {
      console.log(this.registerData);
      if (this.registerData.phoneValidateCode.length != 6) {
        this.$message.error("短信验证码输入不正确,请重新输入");
        this.$refs.phoneValidateCode.focus();
        return;
      }
      updateuser(this.registerData).then(res => {
        if (res.success) {
          this.$message({
            message: "修改个人信息成功!",
            type: "success"
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          this.$message.error(res.errorMessage);
        }
        this.query();
      });
    },
    selectPannel(pannelId) {
      document.getElementById("li_passlist").setAttribute("class", "");
      document.getElementById("li_order").setAttribute("class", "");
      document.getElementById("li_changepwd").setAttribute("class", "");
      document.getElementById("li_userinfo").setAttribute("class", "");
      document.getElementById("li_memberHand").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    },
    phoneCodeIntevel() {
      var that = this;
      console.log("倒计时");
      that.phoneCodeIntervalMax = 60;
      var interval = setInterval(function() {
        console.log("倒计时");
        var remainSecond = that.phoneCodeIntervalMax--;
        if (remainSecond <= 0) {
          clearInterval(interval);
          that.phoneValidateCodeLabel = "获取验证码";
          return;
        }
        that.phoneValidateCodeLabel = remainSecond + "秒后重新获取";
      }, 1000);
    },
    // 复制id
    copyId(content) {
      if (!document.queryCommandSupported("copy")) {
        // 不支持
        this.$message.error("浏览器不支持");
        return false;
      }
      let textarea = document.createElement("textarea");
      textarea.value = content;
      textarea.readOnly = "readOnly";
      document.body.appendChild(textarea);
      textarea.select(); // 选择对象
      textarea.setSelectionRange(0, content.length); //核心
      let result = document.execCommand("copy"); // 执行浏览器复制命令
      textarea.remove();
      this.$message({
        message: "复制成功",
        type: "success"
      });
    },
    // 会员权益
    queryMemberRight() {
      queryMemberRight({ t: {} }).then(res => {
        if (res.success) {
          console.log(res.data.data);
          this.memberGradeRight = res.data.memberGradeRight;
        } else {
          this.$message.error(res.errorMessage);
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.form-group .form-required {
  margin-left: 8px;
  font-size: 26px;
  font-weight: 700;
  padding-top: 4px;
  color: #e34c4c;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
}

.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.left_pannel {
  height: 500px;
  background-color: #f5f5f5;
  overflow: hidden;
}

.right_pannel {
  position: relative;
  background-color: #ffffff;
  min-height: 500px;
  width: 100%;
  border-radius: 10px;
  /* border: 1px solid #ededed; */
}

.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}

.col_menu ul {
  margin-bottom: 20px;
}

ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}

.col_menu li {
  margin: 10px 0;
  position: relative;
}

.col_menu li.current {
  background: none;
}

.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}

.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}

a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.warm-prompt {
  padding: 40px 50px 80px;
}

.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.order-search .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.order-search .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.order-search .service-forms > li {
  margin-top: 20px;
}

.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}

.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2c6e8b;
  text-align: left;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}

.popcontext .tab {
  z-index: 1000;
  width: 240px;
}

.popcontext .tab li {
  height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #4e617f; */
  background-color: #f5f5f5;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}

.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}

.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.service-forms > li {
  margin-top: 15px;
}

.strength-container .strength-bar {
  display: block;
  padding: 2px;
  height: 9px;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
}

.strength-container .strength-bar div {
  display: block;
  float: left;
  width: 60px;
  height: 8px;
  background-color: #cdcdcd;
}

.strength-container .strength-bar div:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.strength-container .strength-bar div:nth-child(2) {
  margin: 0 3px;
}

.strength-container .strength-bar div:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.strength-container .strength-text {
  padding: 4px 0 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  color: #4d4e4e;
  text-align: center;
}

.strength-container .strength-text span {
  display: inline-block;
  width: 33.33%;
}

.strength-container.weak > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.middle > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.middle > .strength-bar div:nth-child(2) {
  background-color: #f88214;
}

.strength-container.strong > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.strong > .strength-bar div:nth-child(2) {
  background-color: #f88214;
}

.strength-container.strong > .strength-bar div:last-child {
  background-color: #6aa001;
}
.pannel-top {
  background-image: url("../../assets/index-1.jpg");
  border-radius: 10px 10px 0 0;
}
.change-btn {
  text-align: left;
  font-size: 12px;
  margin-left: 10px;
  color: #009ed0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.validate-btn {
  width: 100px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d70039;
  cursor: pointer;
  border-radius: 5px;
  background: #ffffff;
  color: #4d4e4e;
}
.member-card {
  border-radius: 20px;
  width: 250px;
  height: 150px;
  background-size: cover;
  margin: 20px;
  position: relative;
}
.verify-tip {
  padding: 0 10px;
  border-top: 1px solid #929a9f;
  margin-top: 10px;
  padding-top: 50px;
  line-height: 30px;
}
.verify-tip p {
  color: #929a9f;
}
.card-left,
.card-right {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 20px;
}
.card-left {
  left: 0;
  border-radius: 0 10px 10px 0;
  // background: url("../../assets/broze-card.png") 50%;
  background-size: cover;
  background-position-x: 50%;
  background-repeat: no-repeat;
}
.card-right {
  right: 0;
  border-radius: 10px 0 0 10px;
  // background: url("../../assets/silver-card.png");
  background-size: cover;
}
.silver-card {
  background: url("../../assets/silver-card.png");
}
.broze-card {
  background: url("../../assets/broze-card.png");
}
.gold-card {
  background: url("../../assets/gold-card.png");
}
.verify-status {
  position: absolute;
  right: 20px;
  top: 79px;
  width: 85px;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 5px;
  border-radius: 10px 0 0 10px;
  color: #d8b88d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-top {
  width: 100%;
  display: flex;
  align-items: center;
}
.card-name-content {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 59px;
  left: 30px;
  position: absolute;
}
.card-name {
  font-size: 21px;
  margin: 0 10px;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-bottom {
  display: flex;
  position: absolute;
  bottom: 31px;
  left: 30px;
  &-item {
    text-align: center;
  }
  &-line {
    width: 1px;
    height: 20px;
    background: #919a9f;
    margin: 0 10px;
  }
}
.card-wrapper {
  display: flex;
  justify-content: flex-start;
  position: relative;
  height: 256px;
  align-items: center;
}
.card-type {
  color: #000000;
  position: absolute;
  right: 90px;
  top: 79px;
  display: flex;
}
.card-id {
  font-size: 16px;
  right: 60px;
  bottom: 31px;
  position: absolute;
  display: flex;
}
.grade-rights {
  width: 928px;
  min-height: 987px;
  margin: 0 auto;
  background: #f7f7f7;
  border-radius: 10px;
  padding: 19px 0;
  margin-top: 10px;
}
.right-title {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  font-size: 21px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  color: #3e3a39;
}
.member-level {
  position: absolute;
  left: 20px;
  top: 120px;
  width: 211px;
  height: 31px;
  line-height: 31px;
  padding-left: 10px;
  background-size: 100% 100%;
  margin-top: 6px;
  .level-image {
    width: 16rpx;
    height: 10rpx;
    margin: 0 5rpx;
  }
}
.grade-info-item {
  width: 311px;
  height: 53px;
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 18px;
}
.grade-current {
  font-size: 14px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
}
.grade-next {
  font-size: 10px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  color: #727171;
}
.copy {
  width: 42px;
  height: 21px;
  margin-left: 5px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.grade-current {
  width: 120px;
  text-align: left;
}
.grade-num {
  font-size: 20px;
  margin-left: 5px;
}
.right-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .right-list {
    width: 439px;
    height: 288px;
    margin: 7px 7px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    .right-items {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .right-item {
      width: 100px;
      margin: 0 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .right-name {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 500;
      color: #3e3a39;
      display: block;
      text-align: center;
      margin: 10px 0 7px 0;
    }
    .right-value {
      display: block;
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 500;
      color: #d6003a;
      text-align: center;
    }
    .right-tips {
      width: 100%;
      text-align: center;
      font-size: 24rpx;
      font-family: Alibaba PuHuiTi;
      font-weight: 500;
      color: #b5b5b6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .right-tip {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 500;
      color: #b5b5b6;
      display: inline-block;
      position: absolute;
      bottom: 24px;
      width: 130px;
      text-align: center;
      margin: 0 auto;
      width: 100%;
    }
    .no-tip {
      bottom: 124px;
    }
    .wx-tip {
      text-align: center;
      font-size: 18px;
      font-family: Alibaba PuHuiTi;
      font-weight: 500;
      color: #b5b5b6;
      position: absolute;
      bottom: 29px;
    }
    .right-top {
      text-align: center;
      width: 100%;
      height: 30px;
      line-height: 30px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0;
      .line {
        width: 227px;
        height: 1px;
        background: #727171;
        position: absolute;
        top: 15px;
      }
      .name {
        display: block;
        width: 120px;
        margin: 0 auto;
        background: #ffffff;
        position: absolute;
        font-size: 21px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #3e3a39;
      }
    }
  }
}
</style>
